

a {
    text-decoration: none;
    color: #673ab7;
}

a:hover {
    text-decoration: none;
}

/*Header*/



.cards-body {
    flex: 1 1 auto;
    padding: 1rem 1rem
}

.card-title {
    margin-bottom: .5rem;
    /* display: flex; */

}

.card-subtitle {
    margin-top: -.25rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link+.card-link {
    margin-left: 1rem
}

.card-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-footer {
    padding: .5rem 1rem;
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid rgba(0, 0, 0, .125)
}

.card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
    margin-right: -.5rem;
    margin-bottom: -.5rem;
    margin-left: -.5rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -.5rem;
    margin-left: -.5rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: calc(.25rem - 1px)
}

.card-img,
.card-img-bottom,
.card-img-top {
    width: 100%
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card-group>.card {
    margin-bottom: .75rem
}

@media (min-width:576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-header,
    .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-footer,
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-header,
    .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-footer,
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.paginations {
    display: flex;
    justify-content: space-between;
}

.paginations button {
    background-color: #428BFA;
    color: white;
    border-radius: 5px;
    width: 100px;
    height: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 17px;
}

.paginations div {
    /* border: 1px solid black; */
    width: 35px;
    background-color: #EEE;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    font-size: 18px;
}


.card-body {
    padding: 10px 30px 20px !important;
}

.upgrade-package-container{

border-radius: 10px ;
box-shadow: 0px 0px 20px -6px rgb(128, 126, 126);
}




@media (max-width:500px) {

    .five-buttons{
        font-size: 10px ;
    } 
}

@media (max-width:460px) {
    .search-and-button {
        align-items: center;
        gap: 20px;
        flex-direction: column-reverse !important;
    }

}


@media (max-width:780px) {

    .card-title {
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }

}


@media (max-width:375px) {
    .date-inputs {
        flex-direction: column;
    }
}

