.user-dropdown{
    display: none;
}

@media (max-width:576px){
    .user-dropdown{
        display: none;
    }

    .onhover-dropdown{
        /* display: none !important; */
    }

}